import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_UNIVERSITY_DETAILS_REQUEST = "GET_UNIVERSITY_DETAILS_REQUEST";
export const GET_UNIVERSITY_DETAILS_SUCCESS = "GET_UNIVERSITY_DETAILS_SUCCESS";
export const GET_UNIVERSITY_DETAILS_FAILURE = "GET_UNIVERSITY_DETAILS_FAILURE";

export const GET_UNIVERSITY_LIST_REQUEST = "GET_UNIVERSITY_LIST_REQUEST";
export const GET_UNIVERSITY_LIST_SUCCESS = "GET_UNIVERSITY_LIST_SUCCESS";
export const GET_UNIVERSITY_LIST_FAILURE = "GET_UNIVERSITY_LIST_FAILURE";

export const CREATE_UNIVERSITY_REQUEST = "CREATE_UNIVERSITY_REQUEST";
export const CREATE_UNIVERSITY_SUCCESS = "CREATE_UNIVERSITY_SUCCESS";
export const CREATE_UNIVERSITY_FAILURE = "CREATE_UNIVERSITY_FAILURE";

export const UPDATE_UNIVERSITY_REQUEST = "UPDATE_UNIVERSITY_REQUEST";
export const UPDATE_UNIVERSITY_SUCCESS = "UPDATE_UNIVERSITY_SUCCESS";
export const UPDATE_UNIVERSITY_FAILURE = "UPDATE_UNIVERSITY_FAILURE";

export const DELETE_UNIVERSITY_REQUEST = "DELETE_UNIVERSITY_REQUEST";
export const DELETE_UNIVERSITY_SUCCESS = "DELETE_UNIVERSITY_SUCCESS";
export const DELETE_UNIVERSITY_FAILURE = "DELETE_UNIVERSITY_FAILURE";

export const getUniversities = (searchQuery) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_UNIVERSITY_LIST_REQUEST });
      const url = `${BASE_URL}/university/admin/list/v1?searchQuery=${searchQuery && searchQuery !== undefined ? searchQuery : ''}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_UNIVERSITY_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_UNIVERSITY_LIST_FAILURE, payload: error.message });
    }
  };
};

export const getUniversitiesDetails = (universityId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_UNIVERSITY_DETAILS_REQUEST });
      const url = `${BASE_URL}/university/admin/${universityId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_UNIVERSITY_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_UNIVERSITY_DETAILS_FAILURE, payload: error.message });
    }
  };
};

export const createUniversity = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_UNIVERSITY_REQUEST });
      let img = "";
      let bg = "";
      if (data?.logo?.name) {
        try {
          const imgRes = await axios.post(
            `${BASE_URL}/blog/admin/upload/blog/image`,
            {
              sFileName: data?.logo.name,
              sContentType: data?.logo.type,
            },
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          );
          const url = imgRes.data.data.sUrl;
          await axios.put(url, data?.logo, {
            headers: { "Content-Type": data?.logo.type },
          });
          img = `${imgRes.data.data.sPath}`
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      } else { 
        img = data.logo
      }

      if (data?.backgroundImage?.name) {
        try {
          const imgRes = await axios.post(
            `${BASE_URL}/blog/admin/upload/blog/image`,
            {
              sFileName: data?.backgroundImage.name,
              sContentType: data?.backgroundImage.type,
            },
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          );
          const url = imgRes.data.data.sUrl;
          await axios.put(url, data?.backgroundImage, {
            headers: { "Content-Type": data?.backgroundImage.type },
          });
          bg = `${imgRes.data.data.sPath}`
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      } else { 
        bg = data.backgroundImage
      }

      const url = `${BASE_URL}/university/admin/create/v1`;
      const response = await axios.post(url, {...data, logo: img, backgroundImage: bg}, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: CREATE_UNIVERSITY_REQUEST, payload: response.data });
      toast.success("University Created Successfully");
      navigate("/university/list");
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_UNIVERSITY_REQUEST, payload: error.message });
    }
  };
};

export const updateUniversity = (universityId, data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_UNIVERSITY_REQUEST });
      let img = "";
      let bg = "";
      if (data?.logo?.name) {
        try {
          const imgRes = await axios.post(
            `${BASE_URL}/blog/admin/upload/blog/image`,
            {
              sFileName: data?.logo.name,
              sContentType: data?.logo.type,
            },
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          );
          const url = imgRes.data.data.sUrl;
          await axios.put(url, data?.logo, {
            headers: { "Content-Type": data?.logo.type },
          });
          img = `${imgRes.data.data.sPath}`
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      } else { 
        img = data.logo
      }


      if (data?.backgroundImage?.name) {
        try {
          const imgRes = await axios.post(
            `${BASE_URL}/blog/admin/upload/blog/image`,
            {
              sFileName: data?.backgroundImage.name,
              sContentType: data?.backgroundImage.type,
            },
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          );
          const url = imgRes.data.data.sUrl;
          await axios.put(url, data?.backgroundImage, {
            headers: { "Content-Type": data?.backgroundImage.type },
          });
          bg = `${imgRes.data.data.sPath}`
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      } else { 
        bg = data.backgroundImage
      }

      const url = `${BASE_URL}/university/admin/update/${universityId}/v1`;
      const response = await axios.put(url, {...data, logo: img, backgroundImage: bg }, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_UNIVERSITY_SUCCESS, payload: response.data });
      toast.success("University Updated Successfully");
      navigate("/university/list");
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_UNIVERSITY_FAILURE, payload: error.message });
    }
  };
};

export const deleteUniversity = (universityId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_UNIVRSITY_UPDATED" });
      dispatch({ type: DELETE_UNIVERSITY_REQUEST });
      const url = `${BASE_URL}/university/admin/delete/${universityId}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_UNIVERSITY_SUCCESS, payload: response.data });
      toast.success("University Deleted Successfully");
      try {
        dispatch({ type: GET_UNIVERSITY_LIST_REQUEST });
        const url = `${BASE_URL}/university/admin/list/v1`;
        const response = await axios.get(url, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        dispatch({ type: GET_UNIVERSITY_LIST_SUCCESS, payload: response.data });
      } catch (error) {
        console.error(error);
        dispatch({ type: GET_UNIVERSITY_LIST_FAILURE, payload: error.message });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_UNIVERSITY_FAILURE, payload: error.message });
    }
  };
};
