import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NavLink from "../components/NavLink";

const sidebarData = [
  { iconClass: "mdi-home", title: "Dashboard", url: "/dashboard" },
  { iconClass: "mdi-account-multiple", title: "Users", url: "/user/list" },
  { iconClass: "mdi-account-multiple", title: "Purchase Course", url: "/purchase/course/list" },
  {
    iconClass: "mdi-account-settings",
    title: "Sub Admin",
    url: "/subAdmin/list",
  },
  { iconClass: "mdi-post", title: "Blogs", url: "/blog/list" },
  { iconClass: "mdi-city", title: "University", url: "/university/list" },
  { iconClass: "mdi-city", title: "Degree", url: "/degree/list" },
  { iconClass: "mdi-update", title: "Years", url: "/year/list" },
  { iconClass: "mdi-bookshelf", title: "Subject", url: "/subject/list" },
  {
    iconClass: "mdi-book-open-page-variant",
    title: "Courses",
    url: "/courses/list",
  },
  { iconClass: "mdi-ticket", title: "Plan", url: "/plan/list" },
  {
    iconClass: "mdi-account-circle",
    title: "Subscriber",
    url: "/subscriber/list",
  },
  { iconClass: "mdi mdi-email", title: "Enquire", url: "/enquire/list" },
  // { iconClass: "mdi mdi-key", title: "Stripe_Keys", url: "/stripe/list" },
  {
    iconClass: "mdi mdi-lock",
    title: "Change Password",
    url: "/change-password",
  },
];

const sidebarAdminData = [
  { iconClass: "mdi-home", title: "Dashboard", url: "/dashboard" },
  // { iconClass: "mdi-account-multiple", title: "Users", url: "/user/list" },
  // { iconClass: "mdi-account-settings", title: "Sub Admin", url: "/subAdmin/list" },
  { iconClass: "mdi-post", title: "Blogs", url: "/blog/list" },
  { iconClass: "mdi-city", title: "University", url: "/university/list" },
  { iconClass: "mdi-city", title: "Degree", url: "/degree/list" },
  { iconClass: "mdi-update", title: "Years", url: "/year/list" },
  { iconClass: "mdi-bookshelf", title: "Subject", url: "/subject/list" },
  // { iconClass: "mdi-ticket", title: "Plan", url: "/plan/list" },
  // {
  //   iconClass: "mdi-account-circle",
  //   title: "Subscriber",
  //   url: "/subscriber/list",
  // },
  // { iconClass: "mdi mdi-email", title: "Enquire", url: "/enquire/list" },
  // { iconClass: "mdi mdi-key", title: "Stripe_Keys", url: "/stripe/list" },
  {
    iconClass: "mdi mdi-lock",
    title: "Change Password",
    url: "/change-password",
  },
];

const Sidebar = () => {
  const location = useLocation();
  const [menuState, setMenuState] = useState({});
  const [bodyClass, setBodyClass] = useState("sidebar-icon-only");

  const userInfo = useSelector((state) => state.userInfo.userInfo);

  const toggleMenuState = (menuStateKey) => {
    setMenuState((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {}),
      [menuStateKey]: !prevState[menuStateKey],
    }));
  };

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    setMenuState({});
  };

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

    return () => {
      // Clean up event listeners if needed
    };
  }, [location]);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        {userInfo?.role === "subAdmin" ? (
          <>
            {sidebarAdminData.map((data) => {
              return (
                <NavLink
                  isPathActive={isPathActive}
                  url={data.url}
                  iconClass={data.iconClass}
                  title={data.title}
                />
              );
            })}
          </>
        ) : (
          userInfo?.role === "admin" && (
            <>
              {sidebarData.map((data) => {
                return (
                  <NavLink
                    isPathActive={isPathActive}
                    url={data.url}
                    iconClass={data.iconClass}
                    title={data.title}
                  />
                );
              })}
            </>
          )
        )}
      </ul>
    </nav>
  );
};

export default Sidebar;
