import * as courseAction from "./courseAction";

export const courseFeatureKey = "courseInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  courses: [],
  courseDetails: {},
  purchaseCourses: [],
  purchaseCourseDetails: {},
  isCourseUpdated: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case courseAction.GET_COURSE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case courseAction.GET_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        courseDetails: payload.data,
      };
    case courseAction.GET_COURSE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        courseDetails: [],
      };
      case courseAction.GET_PURCHASE_COURSE_DETAILS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case courseAction.GET_PURCHASE_COURSE_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          purchaseCourseDetails: payload.data,
        };
      case courseAction.GET_PURCHASE_COURSE_DETAILS_FAILURE:
        return {
          ...state,
          loading: false,
          errorMsg: payload,
          purchaseCourseDetails: [],
        };
      case courseAction.GET_PURCHASE_COURSE_LIST_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case courseAction.GET_PURCHASE_COURSE_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          purchaseCourses: payload.data,
        };
      case courseAction.GET_PURCHASE_COURSE_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          errorMsg: payload,
        };
    case courseAction.GET_COURSE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case courseAction.GET_COURSE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: payload.data,
      };
    case courseAction.GET_COURSE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case courseAction.CREATE_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case courseAction.CREATE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        isCourseUpdated: true,
      };
    case courseAction.CREATE_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case courseAction.UPDATE_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case courseAction.UPDATE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        isCourseUpdated: true,
      };
    case courseAction.UPDATE_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case courseAction.DELETE_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case courseAction.DELETE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        isCourseUpdated: true,
      };
    case courseAction.DELETE_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case "IS_COURSE_UPDATED":
      return {
        ...state,
        isCourseUpdated: false,
      };
    default:
      return state;
  }
};
