import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const S3_BASE_URL = process.env.REACT_APP_AWS_URL;

export const GET_ALL_SUBJECTS_LIST_REQUEST = "GET_ALL_SUBJECTS_LIST_REQUEST";
export const GET_ALL_SUBJECTS_LIST_SUCCESS = "GET_ALL_SUBJECTS_LIST_SUCCESS";
export const GET_ALL_SUBJECTS_LIST_FAILURE = "GET_ALL_SUBJECTS_LIST_FAILURE";

export const CREATE_NEW_SUBJECT_REQUEST = "CREATE_NEW_SUBJECT_REQUEST";
export const CREATE_NEW_SUBJECT_SUCCESS = "CREATE_NEW_SUBJECT_SUCCESS";
export const CREATE_NEW_SUBJECT_FAILURE = "CREATE_NEW_SUBJECT_FAILURE";

export const GET_TOPICS_LIST_REQUEST = "GET_TOPICS_LIST_REQUEST";
export const GET_TOPICS_LIST_SUCCESS = "GET_TOPICS_LIST_SUCCESS";
export const GET_TOPICS_LIST_FAILURE = "GET_TOPICS_LIST_FAILURE";

export const GET_TOPICS_DETAILS_REQUEST = "GET_TOPICS_DETAILS_REQUEST";
export const GET_TOPICS_DETAILS_SUCCESS = "GET_TOPICS_DETAILS_SUCCESS";
export const GET_TOPICS_DETAILS_FAILURE = "GET_TOPICS_DETAILS_FAILURE";

export const UPLOAD_PDF_REQUEST = "UPLOAD_PDF_REQUEST";
export const UPLOAD_PDF_SUCCESS = "UPLOAD_PDF_SUCCESS";
export const UPLOAD_PDF_FAILURE = "UPLOAD_PDF_FAILURE";

export const UPLOAD_VIDEO_REQUEST = "UPLOAD_VIDEO_REQUEST";
export const UPLOAD_VIDEO_SUCCESS = "UPLOAD_VIDEO_SUCCESS";
export const UPLOAD_VIDEO_FAILURE = "UPLOAD_VIDEO_FAILURE";

export const UPLOAD_FLASHCARDS_REQUEST = "UPLOAD_FLASHCARDS_REQUEST";
export const UPLOAD_FLASHCARDS_SUCCESS = "UPLOAD_FLASHCARDS_SUCCESS";
export const UPLOAD_FLASHCARDS_FAILURE = "UPLOAD_FLASHCARDS_FAILURE";

export const CREATE_TOPIC_REQUEST = "CREATE_TOPIC_REQUEST";
export const CREATE_TOPIC_SUCCESS = "CREATE_TOPIC_SUCCESS";
export const CREATE_TOPIC_FAILURE = "CREATE_TOPIC_FAILURE";

export const CREATE_NOTES_REQUEST = "CREATE_NOTES_REQUEST";
export const CREATE_NOTES_SUCCESS = "CREATE_NOTES_SUCCESS";
export const CREATE_NOTES_FAILURE = "CREATE_NOTES_FAILURE";

export const CREATE_COPY_SUBJECT_REQUEST = "CREATE_COPY_SUBJECT_REQUEST";
export const CREATE_COPY_SUBJECT_SUCCESS = "CREATE_COPY_SUBJECT_SUCCESS";
export const CREATE_COPY_SUBJECT_FAILURE = "CREATE_COPY_SUBJECT_FAILURE";

export const GET_SUBJECT_DETAILS_REQUEST = "GET_SUBJECT_DETAILS_REQUEST";
export const GET_SUBJECT_DETAILS_SUCCESS = "GET_SUBJECT_DETAILS_SUCCESS";
export const GET_SUBJECT_DETAILS_FAILURE = "GET_SUBJECT_DETAILS_FAILURE";

export const GET_FLASH_CARD_DETAILS_REQUEST = "GET_FLASH_CARD_DETAILS_REQUEST";
export const GET_FLASH_CARD_DETAILS_SUCCESS = "GET_FLASH_CARD_DETAILS_SUCCESS";
export const GET_FLASH_CARD_DETAILS_FAILURE = "GET_FLASH_CARD_DETAILS_FAILURE";

export const GET_MCQ_DETAILS_REQUEST = "GET_MCQ_DETAILS_REQUEST";
export const GET_MCQ_DETAILS_SUCCESS = "GET_MCQ_DETAILS_SUCCESS";
export const GET_MCQ_DETAILS_FAILURE = "GET_MCQ_DETAILS_FAILURE";

export const GET_PDF_DETAILS_REQUEST = "GET_PDF_DETAILS_REQUEST";
export const GET_PDF_DETAILS_SUCCESS = "GET_PDF_DETAILS_SUCCESS";
export const GET_PDF_DETAILS_FAILURE = "GET_PDF_DETAILS_FAILURE";

export const GET_VIDEO_DETAILS_REQUEST = "GET_VIDEO_DETAILS_REQUEST";
export const GET_VIDEO_DETAILS_SUCCESS = "GET_VIDEO_DETAILS_SUCCESS";
export const GET_VIDEO_DETAILS_FAILURE = "GET_VIDEO_DETAILS_FAILURE";

export const DELETE_SUBJECT_REQUEST = "DELETE_SUBJECT_REQUEST";
export const DELETE_SUBJECT_SUCCESS = "DELETE_SUBJECT_SUCCESS";
export const DELETE_SUBJECT_FAILURE = "DELETE_SUBJECT_FAILURE";

export const UPDATE_SUBJECT_REQUEST = "UPDATE_SUBJECT_REQUEST";
export const UPDATE_SUBJECT_SUCCESS = "UPDATE_SUBJECT_SUCCESS";
export const UPDATE_SUBJECT_FAILURE = "UPDATE_SUBJECT_FAILURE";

export const DELETE_TOPIC_REQUEST = "DELETE_TOPIC_REQUEST";
export const DELETE_TOPIC_SUCCESS = "DELETE_TOPIC_SUCCESS";
export const DELETE_TOPIC_FAILURE = "DELETE_TOPIC_FAILURE";

export const UPDATE_TOPIC_REQUEST = "UPDATE_TOPIC_REQUEST";
export const UPDATE_TOPIC_SUCCESS = "UPDATE_TOPIC_SUCCESS";
export const UPDATE_TOPIC_FAILURE = "UPDATE_TOPIC_FAILURE";

export const DELETE_FLASHCARD_REQUEST = "DELETE_FLASHCARD_REQUEST";
export const DELETE_FLASHCARD_SUCCESS = "DELETE_FLASHCARD_SUCCESS";
export const DELETE_FLASHCARD_FAILURE = "DELETE_FLASHCARD_FAILURE";

export const DELETE_MCQ_REQUEST = "DELETE_MCQ_REQUEST";
export const DELETE_MCQ_SUCCESS = "DELETE_MCQ_SUCCESS";
export const DELETE_MCQ_FAILURE = "DELETE_MCQ_FAILURE";

export const UPDATE_FLASHCARD_REQUEST = "UPDATE_FLASHCARD_REQUEST";
export const UPDATE_FLASHCARD_SUCCESS = "UPDATE_FLASHCARD_SUCCESS";
export const UPDATE_FLASHCARD_FAILURE = "UPDATE_FLASHCARD_FAILURE";

export const DELETE_VIDEO_REQUEST = "DELETE_VIDEO_REQUEST";
export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
export const DELETE_VIDEO_FAILURE = "DELETE_VIDEO_FAILURE";

export const UPDATE_VIDEO_REQUEST = "UPDATE_VIDEO_REQUEST";
export const UPDATE_VIDEO_SUCCESS = "UPDATE_VIDEO_SUCCESS";
export const UPDATE_VIDEO_FAILURE = "UPDATE_VIDEO_FAILURE";

export const DELETE_PDF_REQUEST = "DELETE_PDF_REQUEST";
export const DELETE_PDF_SUCCESS = "DELETE_PDF_SUCCESS";
export const DELETE_PDF_FAILURE = "DELETE_PDF_FAILURE";

export const UPLOAD_CSV_REQUEST = "UPLOAD_CSV_REQUEST";
export const UPLOAD_CSV_SUCCESS = "UPLOAD_CSV_SUCCESS";
export const UPLOAD_CSV_FAILURE = "UPLOAD_CSV_FAILURE";

export const UPDATE_PDF_REQUEST = "UPDATE_PDF_REQUEST";
export const UPDATE_PDF_SUCCESS = "UPDATE_PDF_SUCCESS";
export const UPDATE_PDF_FAILURE = "UPDATE_PDF_FAILURE";

export const uploadMcqCsvFile = (subjectId, topicId, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_CSV_REQUEST });

      // upload occasion image
      if (data && data.file) {
        const imgRes = await axios.post(
          `${BASE_URL}/mcq/cards/admin/upload/file/v1`,
          {
            sFileName: data.file.name,
            sContentType: data.file.type,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        const imageUrl = imgRes.data.data.sUrl;
        const csvFile = imgRes.data.data.sPath;
        await axios.put(imageUrl, data.file, {
          headers: { "Content-Type": data.file.type },
        });

        const url = `${BASE_URL}/mcq/cards/admin/upload/mcq/csv/file/${subjectId}/${topicId}/v1`;
        const response = await axios.post(
          url,
          { url: `${S3_BASE_URL}${csvFile}` },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        console.log(response.data.data);
        dispatch({ type: UPLOAD_CSV_SUCCESS, payload: response.data });
      } else {
        // setAlertModal(true);
        // setMessage("Something went wrong with CSV file");
        // toast("Something went wrong with CSV file");
        return;
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: UPLOAD_CSV_FAILURE, payload: error.message });
      // toast(error?.response?.data?.message);
      // setMessage(error?.response?.data?.message);
      // setAlertModal(true);
    }
  };
};

export const uploadFlashCsvFile = (subjectId, topicId, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_CSV_REQUEST });

      // upload occasion image
      if (data && data.file) {
        const imgRes = await axios.post(
          `${BASE_URL}/mcq/cards/admin/upload/file/v1`,
          {
            sFileName: data.file.name,
            sContentType: data.file.type,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        const imageUrl = imgRes.data.data.sUrl;
        const csvFile = imgRes.data.data.sPath;
        await axios.put(imageUrl, data.file, {
          headers: { "Content-Type": data.file.type },
        });

        const url = `${BASE_URL}/flash/cards/admin/upload/flashcard/csv/file/${subjectId}/${topicId}/v1`;
        const response = await axios.post(
          url,
          { url: `${S3_BASE_URL}${csvFile}` },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        console.log(response.data.data);
        dispatch({ type: UPLOAD_CSV_SUCCESS, payload: response.data });
      } else {
        // setAlertModal(true);
        // setMessage("Something went wrong with CSV file");
        // toast("Something went wrong with CSV file");
        return;
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: UPLOAD_CSV_FAILURE, payload: error.message });
      // toast(error?.response?.data?.message);
      // setMessage(error?.response?.data?.message);
      // setAlertModal(true);
    }
  };
};

export const getSubjectsList = (searchQuery) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_SUBJECTS_LIST_REQUEST });
      const url = `${BASE_URL}/subject/admin/get/subject/list/v1?searchQuery=${
        searchQuery && searchQuery !== undefined ? searchQuery : ""
      }`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_ALL_SUBJECTS_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_ALL_SUBJECTS_LIST_FAILURE, payload: error.message });
    }
  };
};

export const getTopicList = (subjectId, searchQuery) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TOPICS_LIST_REQUEST });
      const url = `${BASE_URL}/topic/admin/get/topic/${subjectId}/list/v1?searchQuery=${
        searchQuery && searchQuery !== undefined ? searchQuery : ""
      }`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_TOPICS_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_TOPICS_LIST_FAILURE, payload: error.message });
    }
  };
};

export const getTopicDetails = (topicId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TOPICS_DETAILS_REQUEST });
      const url = `${BASE_URL}/topic/admin/get/topic/${topicId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_TOPICS_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_TOPICS_DETAILS_FAILURE, payload: error.message });
    }
  };
};

export const uploadPdf = (topicId, data, file, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_PDF_REQUEST });
      if (file.length > 0) {
        const imgArr = [];
        const uploadPromises = file.map(async (file) => {
          try {
            const imgRes = await axios.post(
              `${BASE_URL}/notes/admin/upload/notes/v1`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        // data.Images = imgArr;
        data.pdf = imgArr[0];
      }

      let solutionPdf = [];
      solutionPdf.push(data);
      const url = `${BASE_URL}/notes/admin/insert/pdf/${topicId}/v1`;

      const response = await axios.put(
        url,
        { solutionPdf },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      toast.success("Successfully Uploaded");
      dispatch({ type: UPLOAD_PDF_SUCCESS, payload: response.data });
      navigate(-1);
    } catch (error) {
      console.error(error);
      dispatch({ type: UPLOAD_PDF_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message);
    }
  };
};

export const uploadVideo = (subjectId, data, file, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_VIDEO_REQUEST });
      if (file.length > 0) {
        const imgArr = [];
        const uploadPromises = file.map(async (file) => {
          try {
            const imgRes = await axios.post(
              `${BASE_URL}/videos/admin/upload/video/v1`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        // data.Images = imgArr;
        data.video = imgArr[0];
      }

      const url = `${BASE_URL}/videos/admin/create/video/${subjectId}/v1`;

      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("Successfully Uploaded");
      dispatch({ type: UPLOAD_VIDEO_SUCCESS, payload: response.data });
      navigate(-1);
    } catch (error) {
      console.error(error);
      dispatch({ type: UPLOAD_VIDEO_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message);
    }
  };
};

export const uploadFlashCards = (subjectId, data, handleClear, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TOPICS_DETAILS_REQUEST });
      const url = `${BASE_URL}/flash/cards/admin/create/cards/${subjectId}/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_TOPICS_DETAILS_SUCCESS, payload: response.data });
      handleClear();
      toast.success("Uploaded Successfully");
      navigate(`/subject/${subjectId}/topic/${data.topicId}`);
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_TOPICS_DETAILS_FAILURE, payload: error.message });
    }
  };
};

export const uploadMcqs = (subjectId, data, handleClear, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TOPICS_DETAILS_REQUEST });
      const url = `${BASE_URL}/mcq/cards/admin/create/cards/${subjectId}/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_TOPICS_DETAILS_SUCCESS, payload: response.data });
      handleClear();
      toast.success("Uploaded Successfully");
      navigate(`/subject/${subjectId}/topic/${data.topicId}`);
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_TOPICS_DETAILS_FAILURE, payload: error.message });
    }
  };
};

export const createTopic = (data, file, description, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_TOPIC_REQUEST });
      if (file.length > 0) {
        const imgArr = [];
        const uploadPromises = file.map(async (file) => {
          try {
            const imgRes = await axios.post(
              `${BASE_URL}/topic/admin/upload/topic/image`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        // data.Images = imgArr;
        data.topicImage = imgArr[0];
      }
      if (description.length > 0) {
        const imgArr = [];
        const uploadPromises = description.map(async (file) => {
          try {
            const imgRes = await axios.post(
              `${BASE_URL}/notes/admin/upload/notes/v1`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        // data.Images = imgArr;
        data.description = imgArr[0];
      }
      const url = `${BASE_URL}/topic/admin/create/topic/v1`;

      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("Added Successfully");
      dispatch({ type: CREATE_TOPIC_SUCCESS, payload: response.data });
      navigate(-1);
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_TOPIC_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createNotes = (subjectId, data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_NOTES_REQUEST });
      const url = `${BASE_URL}/notes/admin/create/notes/${subjectId}/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: CREATE_NOTES_SUCCESS, payload: response.data });
      toast.success("Uploaded Successfully");
      navigate(-1);
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_NOTES_FAILURE, payload: error.message });
    }
  };
};

export const copySubject = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_COPY_SUBJECT_REQUEST });
      const url = `${BASE_URL}/subject/admin/create/copy/subject/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: CREATE_COPY_SUBJECT_SUCCESS, payload: response.data });
      toast.success("Updated Successfully");
      navigate("/subject/list");
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_COPY_SUBJECT_FAILURE, payload: error.message });
    }
  };
};

export const createSubject = (data, file, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_TOPIC_REQUEST });
      if (file.length > 0) {
        const imgArr = [];
        const uploadPromises = file.map(async (file) => {
          try {
            const imgRes = await axios.post(
              `${BASE_URL}/subject/admin/upload/subject/image/v1`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        // data.Images = imgArr;
        data.subjectImage = imgArr[0];
      }

      const url = `${BASE_URL}/subject/admin/create/subject/v1`;

      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("Added Successfully");
      dispatch({ type: CREATE_TOPIC_SUCCESS, payload: response.data });
      navigate("/subject/list");
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_TOPIC_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getSubject = (subjectId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBJECT_DETAILS_REQUEST });
      const url = `${BASE_URL}/subject/admin/get/subject/${subjectId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_SUBJECT_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_SUBJECT_DETAILS_FAILURE, payload: error.message });
    }
  };
};

export const deleteSubject = (subjectId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_SUBJECT_UPDATED" });
      dispatch({ type: DELETE_SUBJECT_REQUEST });
      const url = `${BASE_URL}/subject/admin/delete/subject/${subjectId}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_SUBJECT_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_SUBJECT_FAILURE, payload: error.message });
    }
  };
};

export const deleteTopic = (topicId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_TOPIC_UPDATED" });
      dispatch({ type: DELETE_TOPIC_REQUEST });
      const url = `${BASE_URL}/topic/admin/delete/topic/${topicId}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_TOPIC_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_TOPIC_FAILURE, payload: error.message });
    }
  };
};

export const deleteFlashCard = (flashCardId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_FLASH_CARD_UPDATED" });
      dispatch({ type: DELETE_FLASHCARD_REQUEST });
      const url = `${BASE_URL}/flash/cards/admin/delete/cards/${flashCardId}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_FLASHCARD_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_FLASHCARD_FAILURE, payload: error.message });
    }
  };
};

export const deleteMcq = (mcqId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_FLASH_CARD_UPDATED" });
      dispatch({ type: DELETE_MCQ_REQUEST });
      const url = `${BASE_URL}/mcq/cards/admin/delete/cards/${mcqId}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_MCQ_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_MCQ_FAILURE, payload: error.message });
    }
  };
};

export const deleteVideo = (videoId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_FLASH_CARD_UPDATED" });
      dispatch({ type: DELETE_VIDEO_REQUEST });
      const url = `${BASE_URL}/videos/admin/delete/video/${videoId}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_VIDEO_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_VIDEO_FAILURE, payload: error.message });
    }
  };
};

export const getFlashCardDetials = (flashCardId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FLASH_CARD_DETAILS_REQUEST });
      const url = `${BASE_URL}/flash/cards/get/cards/details/${flashCardId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: GET_FLASH_CARD_DETAILS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_FLASH_CARD_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getMcqDetials = (mcqId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_MCQ_DETAILS_REQUEST });
      const url = `${BASE_URL}/mcq/cards/get/cards/details/${mcqId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: GET_MCQ_DETAILS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_MCQ_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateFlashCard = (flashCardId, data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_FLASHCARD_REQUEST });
      const url = `${BASE_URL}/flash/cards/admin/update/cards/${flashCardId}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_FLASHCARD_SUCCESS, payload: response.data });
      toast.success("Updated Successfully");
      navigate(-1);
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_FLASHCARD_FAILURE, payload: error.message });
    }
  };
};

export const updateMcq = (mcqId, data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_FLASHCARD_REQUEST });
      const url = `${BASE_URL}/mcq/cards/admin/update/cards/${mcqId}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_FLASHCARD_SUCCESS, payload: response.data });
      toast.success("Updated Successfully");
      navigate(-1);
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_FLASHCARD_FAILURE, payload: error.message });
    }
  };
};

export const updateSubject = (subjectId, data, file, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SUBJECT_REQUEST });
      if (file.length > 0) {
        const imgArr = [];
        const uploadPromises = file.map(async (file) => {
          try {
            const imgRes = await axios.post(
              `${BASE_URL}/subject/admin/upload/subject/image/v1`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        // data.Images = imgArr;
        data.subjectImage = imgArr[0];
      }

      const url = `${BASE_URL}/subject/admin/update/subject/${subjectId}/v1`;

      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("Updated Successfully");
      dispatch({ type: UPDATE_SUBJECT_SUCCESS, payload: response.data });
      navigate("/subject/list");
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_SUBJECT_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message);
    }
  };
};

export const deletePDF = (topicId, pdfId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_FLASH_CARD_UPDATED" });
      dispatch({ type: DELETE_PDF_REQUEST });
      const url = `${BASE_URL}/notes/admin/delete/pdf/${topicId}/${pdfId}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_PDF_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_PDF_FAILURE, payload: error.message });
    }
  };
};

export const updateTopic = (topicId, data, file, description, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_TOPIC_REQUEST });
      if (file.length > 0) {
        const imgArr = [];
        const uploadPromises = file.map(async (file) => {
          try {
            const imgRes = await axios.post(
              `${BASE_URL}/topic/admin/upload/topic/image`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        // data.Images = imgArr;
        data.topicImage = imgArr[0];
      }

      if (Array.isArray(description) && description.length > 0) {
        const imgArr = [];
        const uploadPromises = description.map(async (file) => {
          try {
            const imgRes = await axios.post(
              `${BASE_URL}/notes/admin/upload/notes/v1`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        // data.Images = imgArr;
        data.description = imgArr[0];
      }
      const url = `${BASE_URL}/topic/admin/update/topic/${topicId}/v1`;

      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // toast.success("Updated Successfully");
      dispatch({ type: UPDATE_TOPIC_SUCCESS, payload: response.data });
      if (navigate) {
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_TOPIC_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getPdfDetails = (topicId, pdfId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PDF_DETAILS_REQUEST });
      const url = `${BASE_URL}/notes/get/pdf/${topicId}/${pdfId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: GET_PDF_DETAILS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_PDF_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getVideoDetails = (videoId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VIDEO_DETAILS_REQUEST });
      const url = `${BASE_URL}/videos/get/video/details/${videoId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: GET_VIDEO_DETAILS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_VIDEO_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updatePdf = (topicId, pdfId, data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PDF_REQUEST });
      const url = `${BASE_URL}/notes/update/pdf/${topicId}/${pdfId}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_PDF_SUCCESS, payload: response.data });
      toast.success("Updated Successfully");
      navigate(-1);
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_PDF_FAILURE, payload: error.message });
    }
  };
};

export const updateVideo = (videoId, data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_VIDEO_REQUEST });
      const url = `${BASE_URL}/videos/admin/update/video/${videoId}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_VIDEO_SUCCESS, payload: response.data });
      toast.success("Updated Successfully");
      navigate(-1);
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_VIDEO_FAILURE, payload: error.message });
    }
  };
};
