import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as subjectAction from "../../../../redux/subject/subjectAction";

const BASE_AWS_URL = process.env.REACT_APP_AWS_URL;

const CopySubjects = () => {
  const [formData, setFormData] = useState({
    selectSubject: "",
    targetSubject: ""
  });
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const subjects = useSelector((state) => state.subjectInfo.subjects);

  useEffect(() => {
    dispatch(subjectAction.getSubjectsList(debouncedSearchQuery));
  }, [])
  
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      selectSubject,
      targetSubject
    } = formData;

    if (
      !selectSubject ||
      !targetSubject ||
      selectSubject === "" ||
      targetSubject === ""
    ) {
      return toast.error("All fields are required");
    }

    dispatch(subjectAction.copySubject(formData, navigate));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "targetSubject" && value !== formData.selectSubject) {
      setFormData((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
      return;
    }
    if (name === "selectSubject" && value !== formData.targetSubject) {
      setFormData((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
      return;
    }
    return toast.error("Value not should be same");
  };

  const handleCancel = () => {
    navigate("/subject/list");
  };

  console.log('formData', formData)
  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">{"Copy Subject"}</h3>
      </div>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                {"Enter Copy Subject Details"}
              </h4>

              <form className="form-sample">
                <div className="form-group">
                  <label>Subject</label>
                  <select
                    value={formData.selectSubject}
                    id="isFreeSelect"
                    class="form-control"
                    onChange={handleChange}
                    name="selectSubject"
                    required
                  >
                    <option value="" disabled>Select Subject ( University - Degree - Year)</option>
                    {
                      subjects?.length > 0 && subjects.map((sub) => {
                        return (
                          <option value={sub?._id} >
                            {sub?.subjectName} ( {sub?.universityDetails?.name} - {sub?.degreeDetails?.name} - {sub?.universityYearsDetails?.year} )
                          </option>
                        )
                      })
                    }
                  </select>
                </div>

                <div className="form-group">
                  <label>Target Subject</label>
                  <select
                    value={formData.targetSubject}
                    id="isFreeSelect"
                    class="form-control"
                    onChange={handleChange}
                    name="targetSubject"
                    required
                  >
                    <option value="" disabled>Select Target Subject ( University - Degree - Year)</option>
                    {
                      subjects?.length > 0 && subjects.map((sub) => {
                        return (
                          <option value={sub?._id}>
                            {sub?.subjectName} ( {sub?.universityDetails?.name} - {sub?.degreeDetails?.name} - {sub?.universityYearsDetails?.year} )
                          </option>
                        )
                      })
                    }
                  </select>
                </div>

                <div className="form-buttons">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-primary mr-2"
                  >
                    Submit
                  </button>
                  <button className="btn btn-light" onClick={handleCancel}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopySubjects;
