import { combineReducers } from "redux";
import * as blogReducer from "./blog/blogReducer";
import * as userReducer from "./user/userReducer";
import * as loginReducer from "./login/loginReducer";
import * as subjectReducer from "./subject/subjectReducer";
import * as planReducer from "./plan/planReducer";
import * as subscriberReducer from "./subscriber/subscriberReducer";
import * as enquireReducer from "./enquire/enquireReducer";
import * as dashboardReducer from "./dashboard/dashboardReducer";
import * as stripeReducer from "./stripe/stripeReducer";
import * as universityReducer from "./university/universityReducer";
import * as yearReducer from "./year/yearReducer";
import * as degreeReducer from "./degree/degreeReducer";
import * as courseReducer from "./courses/courseReducer";

export const rootReducer = combineReducers({
  [blogReducer.blogFeatureKey]: blogReducer.reducer,
  [loginReducer.loginFeatureKey]: loginReducer.reducer,
  [userReducer.userFeatureKey]: userReducer.reducer,
  [subjectReducer.subjectFeatureKey]: subjectReducer.reducer,
  [planReducer.planFeatureKey]: planReducer.reducer,
  [subscriberReducer.subscriberFeatureKey]: subscriberReducer.reducer,
  [enquireReducer.enquireFeatureKey]: enquireReducer.reducer,
  [dashboardReducer.dashboardFeatureKey]: dashboardReducer.reducer,
  [stripeReducer.stripeFeatureKey]: stripeReducer.reducer,
  [universityReducer.universityFeatureKey]: universityReducer.reducer,
  [yearReducer.yearFeatureKey]: yearReducer.reducer,
  [degreeReducer.degreeFeatureKey]: degreeReducer.reducer,
  [courseReducer.courseFeatureKey]: courseReducer.reducer,
});
